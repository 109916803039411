/**
 * 活动管理-活动信息-参与信息-详情页
 * luxinwen
 * 2023-03-22
 */
<style scoped lang="less">
  @import "./edit.less";
</style>

<template>
  <div>
    <Drawer :title="title" v-model="myValue" width="900" :before-close="beforeClose">
      <div class="page-main-content">
        <div class="page-main-content-thead">参与人</div>
        <div class="page-main-content-tbody">
          <Row>
            <Col span="8">参与人名称：{{ formData.customerName }}</Col>
            <Col span="8">性别：{{ genderList[formData.gender] }}</Col>
            <Col span="8">电话：{{ formData.phone }}</Col>
            <Col span="24">参与活动名称：{{ formData.activityName }}</Col>
          </Row>
        </div>
      </div>
      <div class="page-main-content">
        <div class="page-main-content-thead">推荐人</div>
        <div class="page-main-content-tbody">
          <Row>
            <Col span="8">推荐人名称：{{ formData.memberNickname }}</Col>
            <Col span="8">电话：{{ formData.memberMobile }}</Col>
            <Col span="8">推荐时间：{{ formData.recommendTime }}</Col>
            <Col span="24" v-show="formData.status === 1 || formData.status === 2">{{ ['到访', '认购'][formData.status - 1] }}剩余有效时间：{{ formData.remainExpiresTimeStr }}</Col>
          </Row>
        </div>
      </div>
      <Divider />
      <div class="timeline">
        <Timeline>
          <TimelineItem :color="formData.status < 1 ? 'gray' : 'blue'">
            <p class="title">推荐</p>
            <p class="time">{{ formData.recommendTime }}</p>
          </TimelineItem>
          <TimelineItem :color="formData.status < 2 ? 'gray' : 'blue'">
            <p class="title">到访</p>
            <p class="time">{{ formData.visitTime }}</p>
          </TimelineItem>
          <TimelineItem :color="formData.status < 3 ? 'gray' : 'blue'">
            <p class="title">认购</p>
            <p class="time">{{ formData.purchaseTime }}</p>
          </TimelineItem>
          <TimelineItem :color="formData.status < 4 ? 'gray' : 'blue'">
            <p class="title">成交</p>
            <p class="time">{{ formData.transactionTime }}</p>
          </TimelineItem>
        </Timeline>
      </div>
      <Spin size="large" fix v-if="isLoading"></Spin>
    </Drawer>
  </div>
</template>

<script>
  import { GENDER } from '@/util/enum';
  const formData = {
    activityName: '', // 参与活动名称
    customerName: '', // 客户姓名
    gender: null, // 客户性别：1男0女
    memberId: '', // 推荐人账号
    memberNickname: '', // 推荐人名称
    memberMobile: '', // 推荐人手机号
    phone: '',  // 客户手机号
    recommendExpiresDay: '',
    remainExpiresTimeStr: '',
    purchaseTime: '', // 认购时间
    recommendTime: '',  // 推荐时间
    status: null, // 推荐状态：0已失效，1已推荐，2已来访，3已认购，4已成交
    transactionTime: '',  // 成交时间
    visitTime: ''  // 来访时间
  };

  export default {
    props: {
      value: {
        type: Boolean,
        default: false
      },
      data: {
        type: Object,
        default() {
          return {};
        }
      },
      flag: {
        type: String
      }
    },
    data() {
      return {
        myValue: this.value,  // 是否显示抽屉
        isInit: false,        // 是否初始化
        isLoading: false,     // 是否加载中
        formData: Object.assign({}, formData),
        backupData: {},
        genderList: GENDER
      };
    },
    computed: {
      title() {
        return '参与人信息';
      },
      isEqual() {
        return this.deepEqual(this.formData, this.backupData);
      },
      isShow() {
        return this.flag === 'show';
      },
      isEdit() {
        return this.flag === 'edit';
      }
    },
    methods: {
      /**
       * 初始化数据
       */
      initData(data = {}, isInit = false) {
        this.formData = this.deepCopy(formData);
        Object.keys(this.formData).forEach(item => {
          if (data.hasOwnProperty(item)) {
            this.formData[item] = data[item];
          }
        });
        if (isInit) {
          this.$nextTick(() => {
            this.backupData = this.deepCopy(this.formData);
            this.isInit = true;
          });
        }
      },
      /**
       * 获取数据
       */
      getData() {
        this.initData(this.data, true);
      },
      /**
       * 关闭前的回调
       */
      beforeClose() {
        return new Promise((resolve, reject) => {
          if (!this.isInit) {
            // 如果未初始化时快速点击关闭，则不做处理
          } else if (this.isEqual) {
            this.myValue = false;
          } else {
            this.$confirm('数据变更后未保存，是否直接关闭？').then(() => {
              this.myValue = false;
            });
          }
        });
      }
    },
    watch: {
      value(val) {
        this.myValue = val;
        if (this.myValue) {
          // 获取数据
          this.getData();
          // 隐藏页面滚动条
          document.querySelector('body').classList.add('hidden');
          // 重置内容域滚动条
          this.$nextTick(() => {
            let $drawer = document.querySelector('.ivu-drawer-body');
            if ($drawer) $drawer.scrollTo(0, 0);
          });
        } else {
          // 清除表单验证效果
          this.$refs.editForm && this.$refs.editForm.resetFields();
          // 重置初始化状态
          this.isInit = false;
          // 恢复页面滚动条
          document.querySelector('body').classList.remove('hidden');
        }
      },
      myValue(val) {
        this.$emit('input', val);
      }
    }
  };
</script>