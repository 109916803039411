/**
 * 活动管理-活动信息-参与信息
 * luxinwen
 * 2023-03-22
 */
<template>
  <div class="page">
    <div class="page-main">
      <div class="top-form" v-grant="'OSP_ACTIVITY_INFO_QUERY'">
        <Form :model="formData" inline>
          <FormItem>
            <Input class="width-s" v-model="formData.customerName" placeholder="请输入参与人名称" clearable />
          </FormItem>
          <FormItem>
            <Input class="width-s" v-model="formData.phone" placeholder="请输入电话" clearable />
          </FormItem>
          <FormItem>
            <Select class="width-s" v-model="formData.gender" placeholder="请选择性别" clearable>
              <Option v-for="(value, key) in genderList" :key="'gender' + key" :value="key">{{ value }}</Option>
            </Select>
          </FormItem>
          <FormItem>
            <Select class="width-s" v-model="formData.status" placeholder="请选择状态" clearable>
              <Option v-for="(value, key) in statusList" :key="'status' + key" :value="key">{{ value }}</Option>
            </Select>
          </FormItem>
          <FormItem>
            <Button type="primary" @click="queryData">查询</Button>
          </FormItem>
        </Form>
      </div>
      <div class="page-main-content">
        <sp-table :columns="filterColumns" :data="tableData" :loading="tableLoading" :total="pager.total" :page="pager.pageNum" :page-size="pager.pageSize" @change="changePage">
          <template slot-scope="params" slot="gender">
            <span>{{ genderList[params.row.gender] }}</span>
          </template>
          <template slot-scope="params" slot="status">
            <span>{{ statusList[params.row.status] }}</span>
          </template>
          <template slot-scope="params" slot="time">
            <span>{{ ['', params.row.recommendTime, params.row.visitTime, params.row.purchaseTime, params.row.transactionTime][params.row.status] }}</span>
          </template>
          <template slot-scope="params" slot="operation">
            <Button @click="showData(params.row)" v-grant="'OSP_ACTIVITY_INFO_VIEW'">查看</Button>
          </template>
        </sp-table>
      </div>
    </div>

    <edit-drawer v-model="drawerDisplay" :data="drawerData" :flag="drawerFlag" @success="getData"></edit-drawer>
  </div>
</template>

<script>
  import { GENDER, ACTIVITY_PART_STATUS } from '@/util/enum';
  import editDrawer from './edit.vue';

  export default {
    components: {
      editDrawer
    },
    data() {
      return {
        formData: {
          customerName: '', // 客户姓名
          phone: '',  // 客户手机号
          gender: null, // 客户性别：1男0女
          status: null // 推荐状态：0已失效，1已推荐，2已来访，3已认购，4已成交
        },
        tableColumns: [
          {
            title: '序号',
            type: 'index',
            width: 70,
            align: 'center'
          },
          {
            title: '活动名称',
            key: 'activityName'
          },
          {
            title: '项目名称',
            key: 'buildingName'
          },
          {
            title: '参与人名称',
            key: 'customerName'
          },
          {
            title: '电话',
            key: 'phone'
          },
          {
            title: '性别',
            slot: 'gender'
          },
          {
            title: '状态',
            slot: 'status'
          },
          {
            title: '状态时间',
            slot: 'time'
          },
          {
            title: '操作',
            slot: 'operation',
            align: 'center'
          }
        ],
        tableData: [],
        tableLoading: false, // 表格查询状态
        pager: {
          total: 0,     // 总条数
          pageNum: 1,   // 当前页码
          pageSize: 10  // 每页条数
        },
        drawerDisplay: false, // 是否显示编辑抽屉
        drawerData: {},       // 编辑抽屉数据
        drawerFlag: 'add',    // 编辑抽屉状态
        dataId: '',
        genderList: GENDER,
        statusList: ACTIVITY_PART_STATUS
      };
    },
    computed: {
      filterColumns() {
        let val = [];
        let grant = this.$grant.OSP_ACTIVITY_INFO_VIEW;
        if (!this.checkGrant(grant)) {
          val = this.tableColumns.filter(item => item.slot !== 'operation');
        } else {
          val = this.tableColumns;
        }
        return val;
      }
    },
    created() {
      this.dataId = this.$route.query.id;
      if (this.dataId) this.getData();
    },
    methods: {
      /**
       * 获取数据
       */
      getData() {
        let data = Object.assign({}, this.formData, {
          activityId: Number(this.dataId),
          pageNum: this.pager.pageNum,
          pageSize: this.pager.pageSize
        });
        this.$axios({
          url: this.$api.activity.queryCustomerList,
          data,
          beforeSend: () => {
            this.tableLoading = true;
          },
          complete: () => {
            this.tableLoading = false;
          }
        }).then(data => {
          this.tableData = data.list || [];
          this.pager.total = data.total || 0;
        });
      },
      /**
       * 查询数据
       */
      queryData() {
        this.pager.pageNum = 1;
        this.getData();
      },
      /**
       * 查看
       */
      showData(row) {
        this.drawerFlag = 'show';
        this.drawerData = row;
        this.drawerDisplay = true;
      },
      /**
       * 切换页码或每条页数
       */
      changePage(pager) {
        this.pager.pageNum = pager.page;
        this.pager.pageSize = pager.pageSize;
        this.getData();
      }
    }
  };
</script>